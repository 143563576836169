import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"

const H1 = styled.h1`
  color: goldenrod;
  a {
    text-decoration: none;
    color: coral;
  }
`

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <H1>
    <div><h1>Sluush!!!!</h1>This is the future Web site of Sluush.</div>
    </H1>
  </Layout>
)

export default IndexPage
